<template>
  <div>
    <h2>Технологические процессы</h2>
    <techprocesses-table></techprocesses-table>
  </div>
</template>

<script>
import TechprocessesTable from "@/components/ecologist/techprocesses/techprocessesTable";
export default {
  name: "techprocessesMain",
  components: { TechprocessesTable },
};
</script>

<style scoped></style>
