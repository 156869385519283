<template>
  <div>
    <techprocesses-main></techprocesses-main>
  </div>
</template>

<script>
import TechprocessesMain from "@/components/ecologist/techprocesses/techprocessesMain";
export default {
  name: "techprocessesMainView",
  components: { TechprocessesMain },
};
</script>

<style scoped></style>
