<template>
  <div>
    <el-form
      :rules="rules"
      :model="editWaste"
      ref="formedForm"
      label-position="top"
      label-width="120px"
    >
      <el-row :gutter="10">
        <el-col :span="24">
          <el-form-item label="Ответственный эколог">
            <el-select
              clearable
              class="searchPole"
              v-model="editWaste.userInfo"
              filterable
              value-key="id"
              placeholder="Ответственный"
            >
              <el-option
                v-for="item in usersByManagerList"
                :key="item.id"
                :label="item.name"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <label style="color:#AAAAAA">
          Максимальное количество данного вида отхода,  находящегося на хранении на начало работы в программе в целом по предприятию: {{Math.round(SS01DictionaryValueSum.valstoreall*10000000)/10000000}} {{ organizationWaste.pss93UnitsInfo.name }}
          <br>
          Текущее значение внесенного количества отхода: {{Math.round((SS01DictionaryValueSum.valstorunik+parseFloat(editWaste.stored.replace(",", ".")))*10000000)/10000000}} {{ organizationWaste.pss93UnitsInfo.name }}
          <div v-if="Math.round(SS01DictionaryValueSum.valstoreall*1000000)/1000000>Math.round((SS01DictionaryValueSum.valstorunik+SS01DictionaryValueSum.valstoreher)*1000000)/1000000&&SS01DictionaryValueSum.valstoreall!=0">Заполненность: {{Math.round((Math.round((SS01DictionaryValueSum.valstorunik+parseFloat(editWaste.stored.replace(",", ".")))*100000000)/(1000000*(Math.round(SS01DictionaryValueSum.valstoreall*10000000)/10000000)))*1000000)/1000000}}%</div>
          <div v-if="Math.round(SS01DictionaryValueSum.valstoreall*1000000)/1000000<Math.round((SS01DictionaryValueSum.valstorunik+SS01DictionaryValueSum.valstoreher)*1000000)/1000000" style="color:red">Заполненность: более 100%</div>
          <div v-if="(SS01DictionaryValueSum.valstoreall==0
          &&Math.round((SS01DictionaryValueSum.valstorunik+SS01DictionaryValueSum.valstoreher)*1000000)/1000000<=0)
          ||(Math.round(SS01DictionaryValueSum.valstoreall*1000000)/1000000==(SS01DictionaryValueSum.valstorunik+SS01DictionaryValueSum.valstoreher))" style="color:green">Заполненность: 100%</div>
      </label>
      <el-row :gutter="10">
        <el-col :span="20">
          <br>
          Фактическое количество отходов, находящихся на хранении в данном подразделении на начало работы в программе.
          <el-row :gutter="10">  
            <el-col :span="20">
              <el-input v-model="editWaste.stored" @change="setval"></el-input>
            </el-col>       
            <el-col :span="4">
              <el-input
                disabled
                placeholder="Выберите ед. измерения"
                v-model="organizationWaste.pss93UnitsInfo.name"
              ></el-input>
            </el-col>
          </el-row> 
        </el-col>
        
      </el-row>
      <el-row :gutter="10">
        <el-col :span="20">
          <br>
          В том числе на долговременном хранении.
          <el-row :gutter="10">  
            <el-col :span="20">
              <el-input v-model="editWaste.storedLong" @change="setval"></el-input>
            </el-col>       
            <el-col :span="4">
              <el-input
                disabled
                placeholder="Выберите ед. измерения"
                v-model="organizationWaste.pss93UnitsInfo.name"
              ></el-input>
            </el-col>
          </el-row> 
        </el-col>
        
      </el-row>
      <br>
      <el-row >
        <el-date-picker 
                  v-model="editWaste.dateFrom"
                  type="date"
                  placeholder = "Дата начала работы"
                  value-format="yyyy-MM-dd"
        ></el-date-picker>
      </el-row>
      <br>
      <el-row>
        <input type="checkbox" id="checkbox" v-model="editWaste.datEd">
        <label for="checkbox">Устаревшее</label>
      </el-row>
      <br>
      <el-row >
        <el-date-picker 
                  v-model="editWaste.dateTo"
                  type="date"
                  :disabled="!editWaste.datEd"
                  placeholder = "Дата окончания работы"
                  value-format="yyyy-MM-dd"
        ></el-date-picker>
      </el-row>
      <el-row>
        <input type="checkbox" id="checkbox2" v-model="editWaste.alLocated" :disabled="!editWaste.datEd">
        <label for="checkbox2" :disabled="!editWaste.datEd">Распределено</label>
      </el-row>
      <br>
      <el-row>
        <el-col :span="24">
          <el-button 
          :disabled="prod==1" 
          @click="save" type="success"
          >Изменить отход</el-button>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { AXIOS } from "@/AXIOS/http-common";
import Cookies from "js-cookie";

export default {
  props: ["editWaste","action"],
  name: "techprocessesEditWasteForm",

  data() {
    return {
      prod:0,
      usersByManagerList: [],
      userblock :[ '16','17','19','90','92','93','96','180', '232','128','156','157', '160', '187','189','186','184','188','190','192','240','243','246','251','265','291','303','305','321','350','364','367','368'],
      SS01DictionaryValueSum: 0,
    };
  },
  computed: {
    ...mapGetters({
      organizationWaste: "GETORGANIZATIONWASTE",
    }),
  },

  methods: {
    async getOrganizationWaste() {
      await this.$store.dispatch(
        "getOrganizationWaste",
        this.editWaste.organizationWasteId
      );
      console.log("Suda");
      console.log(this);
    },
    
    save() {
      if (!this.editWaste.datEd)
        this.editWaste.alLocated = false;
      if (this.editWaste.storedLong==-2)
        this.editWaste.storedLong=null;
      if (this.editWaste.userInfo=="")
      this.editWaste.userInfo={datEd: null,dateTo: null,id: null,name: null,name2: null,parent: 0};
      this.$refs["formedForm"].validate((valid) => {
        if (valid) {
          if (this.editWaste.stored === "") {
            this.editWaste.stored = 0;
          }
          this.$store
            .dispatch("updateWasteToTechProcess", this.editWaste)
            .then(() => {
              this.notification("Успешно", "Отход изменен", "success");
              this.$store.dispatch("setShowModal", null);
              this.$store.dispatch("getListAllTechProcesses", {
                page: this.action.page,
                size: this.action.size,
              });
            });
        }
      });
    },

    setval()
    {
      this.SS01DictionaryValueSum.valstoreher = parseFloat(this.editWaste.stored.replace(",", "."));
  },

    async getvalor()
    {
    return await AXIOS.get(
      "classifiers/ss01dictionary/SS01DictionaryValueSum?Id=" + this.editWaste.organizationWasteId+ "&tphw=" + this.editWaste.techProcessHasWasteId
    ).then((response) => {
      console.log(response);
      this.SS01DictionaryValueSum = response.data;
    });
    
  },

  },

  async mounted() {
    this.prod = Cookies.get('prod');
    this.user_id= Cookies.get('userId');
    await AXIOS.get("/user/get/users/by/manager").then((response) => {
      console.log(response.data);
      this.usersByManagerList = response.data;
      this.usersByManagerList.push({id:null,name:""});
    });
    await this.getvalor();
    this.getOrganizationWaste();    
  },
};
</script>

<style scoped></style>
