<template>
  <div>
    <search-block :title="'тех. процесса'">
      <el-row :gutter="10">
        <el-col :span="20">
          <el-input @input="searchTechProcess" v-model="search"></el-input>
        </el-col>
        <el-col :span="4">
          <el-button type="primary" @click="showAddModal"
            >Добавить тех процесс</el-button
          >
        </el-col>
      </el-row>
    </search-block>

    <el-divider></el-divider>
    <el-table stripe :data="techProcesses.content">
      <el-table-column label="Наименование">
        <template slot-scope="item">
          <div :style="new Date(item.row.dateFrom) > new Date() && item.row.dateFrom!=null ? { color: '#e2eb34' } : new Date(item.row.dateTo) < new Date() && item.row.dateTo!=null ? { color: '#eb6b34' } : {}">
            {{item.row.processId +'/'+ item.row.processName }} {{ item.row.path }} 
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Отходы тех. процесса/ответственный эколог">
        <template slot-scope="item">
          <el-row
            style="margin-bottom: 5px"
            :key="key"
            v-for="(waste, key) in item.row.techProcessWasteDTO"
          >
            <el-col :span="11">
              <div :style="(new Date(waste.dateFrom) > new Date() && waste.dateFrom!=null) || (new Date(waste.owDateFrom) > new Date() && waste.owdateFrom!=null)
              ? { color: '#e2eb34'} 
              : (new Date(waste.dateTo) < new Date() && waste.dateTo!=null) || (new Date(waste.owDateTo) < new Date() && waste.owdateTo!=null)
              ? { color: '#eb6b34'} 
              : {}">
              {{waste.techProcessHasWasteId + ' / ' +
                (waste.wasteInfo.name !== "-1--1"
                  ?  waste.wasteInfo.name
                  : "Сведения отсутствуют")
              }}
              </div>
              </el-col
            >
            <el-col :span="11">
              |
              {{
                waste.userInfo.name !== "-1"
                  ? waste.userInfo.name
                  : "Сведения отсутствуют"
              }}</el-col
            >
            <el-col :span="1">
              <el-button
                @click="editWaste(waste)"
                style="padding: 7px"
                type="warning"
                size="mini"
              >
                <i class="el-icon-edit"></i>
              </el-button>
            </el-col>
            <el-col :span="1">
              <el-button
                :disabled="prod==1"
                @click="deleteWaste(waste.techProcessHasWasteId)"
                style="padding: 7px"
                type="danger"
                size="mini"
              >
                <i class="el-icon-close"></i>
              </el-button>
            </el-col>
          </el-row>
        </template>
      </el-table-column>
      <el-table-column label="" width="150">
        <template slot-scope="item">
          <el-row :gutter="10">
            <el-col :span="24">
              <el-button
                :disabled="prod==1"
                @click="deleteTechProc(item.row)"
                size="mini"
                style="float: right; width: 100%"
                type="danger"
                >Удалить</el-button
              >
              <el-button
              :disabled="prod==1"
                size="mini"
                style="float: right; width: 100%; margin-top: 5px"
                @click="edit(item.row)"
                type="warning"
                >Редактировать</el-button
              >
              <el-button
              :disabled="prod==1"
                size="mini"
                style="width: 100%; margin-left: 0; margin-top: 5px"
                @click="addWaste(item.row)"
                type="primary"
                >Добавить отход</el-button
              >
            </el-col>
          </el-row>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      style="text-align: center"
      layout="total, sizes, prev, pager, next"
      background
      :page-sizes="[20, 40, 60, 80, 100]"
      :page-size="techProcesses.size"
      :current-page.sync="techProcesses.number + 1"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :total="techProcesses.totalElements"
    >
    </el-pagination>

    <custom-modal
    
      title="Добавить тех. процесс"
      :visible="showModal === 'addTechProcess'"
    >
      <techprocesses-add :action="action"></techprocesses-add>
    </custom-modal>

    <custom-modal
      title="Изменить тех. процесс"
      :visible="showModal === 'updateTechProcess'"
    >
      <techprocesses-edit :action="action"
        :selected-tech-process="selectedTechProcess"
      ></techprocesses-edit>
    </custom-modal>

    <custom-modal 
      title="Добавить отход"
      :visible="showModal === 'addWasteToTechProcess'"
    >
      <tech-processes-add-waste-form :action="action"
        :selected-tech-process="selectedTechProcess"
      ></tech-processes-add-waste-form>
    </custom-modal>

    <custom-modal
      title="Изменить отход"
      :visible="showModal === 'updateWasteToTechProcess'"
      v-if="showModal === 'updateWasteToTechProcess'"
    >
      <techprocesses-edit-waste-form :action="action"
        :edit-waste="selectedTechProcess"
      ></techprocesses-edit-waste-form>
    </custom-modal>
  </div>
</template>

<script>
import Cookies from "js-cookie";
import { mapGetters } from "vuex";
import CustomModal from "@/components/customModal";
import TechprocessesAdd from "@/components/ecologist/techprocesses/techprocessesAdd";
import notification from "@/mixins/notification";
import TechprocessesEdit from "@/components/ecologist/techprocesses/techprocessesEdit";
import TechProcessesAddWasteForm from "@/components/ecologist/techprocesses/techProcessesAddWasteForm";
import SearchBlock from "@/components/searchBlock";
import TechprocessesEditWasteForm from "@/components/ecologist/techprocesses/techprocessesEditWasteForm";

export default {
  
  name: "techprocessesTable",
  components: {
    TechprocessesEditWasteForm,
    SearchBlock,
    TechProcessesAddWasteForm,
    TechprocessesEdit,
    TechprocessesAdd,
    CustomModal,
  },
  mixins: [notification],
  data() {
    return {
      prod:0,
      action: {page:0,size:20},
      user_id: 0,
      userblock :[ '16','17','19','90','92','93','96','180', '232','128','156','157', '160', '187','189','186','184','188','190','192','240','243','246','251','265','291','303','305','321','350','364','367','368'],
      selectedTechProcess: {},
      search: null,
    };
  },

  computed: {
    ...mapGetters({
      techProcesses: "GETLISTTECHPROCESSES",
      showModal: "GETSHOWMODAL",
    }),
  },

  methods: {
    editWaste(item) {
      if (item.dateTo!=null)
        item.dateTo=new Date(item.dateTo);
      if (item.dateFrom!=null)
        item.dateFrom=new Date(item.dateFrom);
      console.log(item);
      if (item.storedLong==-2)
      item.storedLong = 0;
      this.$store.dispatch("setShowModal", "updateWasteToTechProcess");
      this.selectedTechProcess = item;
    },

    searchTechProcess(query) {
      this.$store.dispatch("searchTechProcess", {
        query: query,
        page: 0,
        size: 20,
      });
    },

    deleteWaste(id) {
      this.confirm(
        "Удалить отхо",
        "Вы действительно хотите удалить отход",
        "warning",
        "Удалить",
        "Отмена"
      ).then(() => {
        this.$store.dispatch("deleteWasteFromTechProcess", id).then(() => {
          this.notification("Успешно", "Отход удален", "success");
          this.getTechProcessesList({ page: this.techProcesses.number,
                size: this.techProcesses.size, });
        });
      });
    },

    showAddModal() {
      this.$store.dispatch("setShowModal", "addTechProcess");
    },

    handleSizeChange(val) {
      this.getTechProcessesList({ page: 0, size: val });
      this.action={ page: 0, size: val };
    },

    handleCurrentChange(val) {
      this.getTechProcessesList({
        page: val - 1,
        size: this.techProcesses.size,
      });
      this.action={ page: val - 1,
        size: this.techProcesses.size, };
    },

    deleteTechProc(item) {
      console.log(item);
      if (item.techProcessWasteDTO.length === 0) {
        this.confirm(
          "Удалить тех. процесс",
          "Вы действительно хотите удалить тех процесс",
          "warning",
          "Удалить",
          "Отмена"
        ).then(() => {
          this.$store
            .dispatch("deleteProcess", item.processId)
            .then((response) => {
              console.log(response.data);
              this.notification("Успешно", "Тех. процесс удален", "success");
              this.getTechProcessesList({
                page: this.techProcesses.number,
                size: this.techProcesses.size,
              });
            });
        });
      } else {
        this.notification(
          "Ошибка",
          "Невозможно удалить тех. процесс у которого есть отходы",
          "error"
        );
      }
    },

    async edit(item) {
      console.log(item);
      this.selectedTechProcess = item;
      if (this.selectedTechProcess.dateFrom!=null)
      this.selectedTechProcess.dateFrom=new Date(this.selectedTechProcess.dateFrom);
      if (this.selectedTechProcess.dateTo!=null)
      this.selectedTechProcess.dateTo=new Date(this.selectedTechProcess.dateTo);

      await this.$store.dispatch("getOrganizationHierarchyByType", { type: 3 });
      await this.$store.dispatch("setShowModal", "updateTechProcess");
    },

    async addWaste(item) {
      console.log(item);
      this.selectedTechProcess = item;
      await this.$store.dispatch(
        "getOrganizationWastesList",
        this.selectedTechProcess.processId
      );
      this.$store.dispatch("setShowModal", "addWasteToTechProcess");
    },

    getTechProcessesList(data) {
      this.$store.dispatch("getListAllTechProcesses", {
        page: data.page,
        size: data.size,
      });
    },
  },

  mounted() {
    this.prod = Cookies.get('prod');
    this.user_id= Cookies.get('userId');;
    this.getTechProcessesList({ page: 0, size: 20 });
  },
};
</script>

<style scoped></style>
